import React from 'react'
import { Container, Row} from "react-bootstrap";
import Cards from '../Blocks/Cards';
import Seo from '../seo'
import Breadcrumbs from "../Blocks/Breadcrumbs"
import Gallery from "../Blocks/Gallery"


function Portfolio(props) {

  const ports = props.pages.filter(page => page.type === 'port')

    return (
      <>
      <Seo
          title={props.content.seoTitle || props.content.h1}
          description={props.content.seoDescription}
          metas={props.global.defaults.metas}
      />
     <Breadcrumbs home={props.global.defaults.lang.home} contentH1={props.content.h1}/>
      <Container style={{maxWidth: 1280, minHeight: 500}}>
      <div id='h1p'>
          <h1 style={{textAlign: 'center'}}>{props.content.h1}</h1>
          <p style={{textAlign: 'center'}}>{props.content.subTitle || ''}</p>
        </div>
        <Row>
              {props.content.cards && props.content.cards !== false ?
             
          <Cards pages={ports} localImages={props.localImages} clickable={props.content.clickable} />
          :null}
        </Row>
        <div style={{marginBottom: 500}}>
              {props.pages[0].content.gallery && props.pages[0].content.gallery.active ===  true ?
          
                  <Gallery
                      localImages={props.localImages}
                      imageNames={props.pages[0].content.gallery.images}
                      /> 
      
          : null}
        </div>
        </Container>
      </>
    )
}

export default Portfolio