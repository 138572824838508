import React from 'react'
import { graphql } from "gatsby"
import Portfolio from '../components/Pages/Portfolio';
import Page from '../components/Pages/Page';

function PortfolioTemplate(props) {
    
    return (
        <Page {...props} PageComponent={ Portfolio } />
    )

}

export const queryPortfolio = graphql`
fragment portfolioFields on NewSites {
    ...globalFields
    pages {
        type
        id
        aUri
        content {
            h1
            cards
            subTitle
            text
            image
            images
            seoTitle
            seoDescription    
            clickable
            cards
            gallery{
                active
                images
                subTitle
                title
            }
        }
    }
}
query portfolioQuery($subDomain: String) {
    newSites(subDomain: {eq: $subDomain}) {
        ...portfolioFields
    }
}`

export default PortfolioTemplate


